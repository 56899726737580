import React, {useState, useEffect} from 'react';
import {request} from '@/utils';
import {Popover, Button} from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons';
import './ContractStatusCounts.css';

const ContractStatusCounts = ({style}) => {
  const [counts, setCounts] = useState({
    0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0,
  });

  const statusLabels = {
    0: '新規会社　契約書なし',
    1: '契約内容が保存された',
    2: '契約書が送信された',
    3: '契約書は署名済み',
    4: '異議あり',
    5: '署名済みの契約書が送信完了',
  };

  useEffect(() => {
    const fetchData = async (status) => {
      try {
        const response = await request.get(`/admin/getAllUsers?contractStatus=${status}`);
        if (response.status === '200') {
          setCounts(prevCounts => ({
            ...prevCounts, [status]: response.data.totalItems,
          }));
        }
      } catch (error) {
        console.error(`获取状态数据时出错 ${status}`, error);
      }
    };

    for (let status = 0; status <= 5; status++) {
      fetchData(status);
    }
  }, []);

  const content = (<div className="status-counts">
    {Object.keys(statusLabels).map(status => (<div key={status} className={`status-item status-${status}`}>
      <span className="status-label">{statusLabels[status]}</span>
      <span className="status-count">{counts[status]}</span>
    </div>))}
  </div>);

  return (<Popover content={content} title="契約状態の詳細" trigger="click">
    <Button
      type="primary"
      icon={<InfoCircleOutlined />}
    >
      契約状態の詳細
    </Button>
  </Popover>);
};

export default ContractStatusCounts;