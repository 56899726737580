import React from 'react';
import {Button} from 'antd';
import {useNavigate} from 'react-router-dom';

const DisputeSubmitted = () => {
  const navigate = useNavigate();

  return (<div style={{textAlign: 'center', padding: '50px'}}>
    <h1>異議内容が送信されました</h1>
    <p>異議内容の送信に成功しました。管理者からの返信をお待ちください。</p>
    <Button type="primary" onClick={() => navigate('/')}>
      ホームに戻る
    </Button>
  </div>);
};

export default DisputeSubmitted;
