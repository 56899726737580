// > 业务背景：封装 `AuthRoute` 路由鉴权高阶组件，实现未登录拦截，并跳转到登录页面
// > 实现思路：判断本地是否有token，如果有，就返回子组件，否则就重定向到登录Login
import { getToken } from '../utils'
import { Navigate } from 'react-router-dom';

export function AuthRoute({ children }) {
  const isToken = getToken()

  if (isToken) {
  // if (1) {
    return <>{children}</>;

  } else {
    return <Navigate to="/login" replace />
  }

}