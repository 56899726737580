import { Layout, Menu, Popconfirm } from 'antd'
import {
  HomeOutlined,
  DiffOutlined,
  LogoutOutlined,
} from '@ant-design/icons'
import './index.scss'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { clearUserInfo, fetchUserInfo } from '@/store/modules/user'
import { getToken } from '@/utils'
import { jwtDecode } from "jwt-decode";

const { Header, Sider } = Layout


const items = [
  {
    label: '契約リスト',
    key: '/',
    icon: <HomeOutlined />,
  },
  // {
  //   label: 'second',
  //   key: '/second',
  //   icon: <DiffOutlined />,
  // },
]

const GeekLayout = () => {
  const navigate = useNavigate()
  const onMenuClick = (route) => {
    const path = route.key
    navigate(path)
  }
  const location = useLocation()
  const selectedKey = location.pathname

  const dispatch = useDispatch()
  // useEffect(() => {
  //   dispatch(fetchUserInfo())
  // }, [dispatch])

  // 处理时token失效
  const token = getToken()
  const decodedToken = jwtDecode(token);
  const name = decodedToken.sub;
  const now = new Date(); // 获取当前时间
  const timestamp = now.getTime() / 1000;// 将当前时间转化成时间戳
  const [collapsed, setCollapsed] = useState(true); // 初始状态为隐藏

  console.log("时间", timestamp)
  console.log("decodedToken时间", decodedToken.exp)

  if (decodedToken.exp < timestamp) {
    dispatch(clearUserInfo())
    navigate('login')
  }

  const onConfirm = () => {
    dispatch(clearUserInfo())
    navigate('/login')
  }

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  return (
    <Layout>
      <Header className="header">
        {/* <div className="logo" /> */}
        <div className='layout-title'>JTMHバス契約システム</div>
        <div className="user-info">
          <span className="user-name">{name}</span>
          <span className="user-logout">
            <Popconfirm title="ログアウトしますか" okText="ログアウト" cancelText="キャンセル" onConfirm={onConfirm}>
              <LogoutOutlined /> ログアウト
            </Popconfirm>
          </span>
        </div>
      </Header>
      <Layout>
        <Sider collapsible collapsed={collapsed} onCollapse={onCollapse} width={200} className="site-layout-background">
          <Menu
            mode="inline"
            theme="dark"
            selectedKeys={selectedKey}
            items={items}
            onClick={onMenuClick}
            style={{ height: '100%', borderRight: 0 }}></Menu>
        </Sider>
        <Layout className="layout-content" style={{ padding: 20 }}>
          <Outlet />
        </Layout>
      </Layout>
    </Layout>
  )
}
export default GeekLayout