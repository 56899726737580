import React, {useState, useEffect} from 'react';
import {Modal, Form, Input, DatePicker, Button, Card, message} from 'antd';
import {CloseOutlined} from '@ant-design/icons';
import ImageWithToggle from '@/pages/Home/components/ImageWithToggle';
import dayjs from 'dayjs';
import {request} from '@/utils';

const {TextArea} = Input;

const EditModal = ({visible, onCancel, form}) => {
  const [templateText, setTemplateText] = useState('');

  const numberToChinese = (num) => {
    const units = ['', '十', '百', '千', '万', '亿'];
    const chars = '零一二三四五六七八九';
    let result = '';
    let i = 0;
    while (num > 0) {
      result = chars[num % 10] + (units[i] || '') + result;
      num = Math.floor(num / 10);
      i++;
    }
    result = result.replace(/^一十/, '十');
    return result;
  };

  const chineseNumbers = ['十六', '十七', '十八', '十九', '二十', '二十一', '二十二', '二十三', '二十四', '二十五', '二十六', '二十七', '二十八', '二十九', '三十'];

  const generateTemplateText = (values) => {
    const additionalFieldsText = values.additionalFields?.map((field, index) => `<span style="color: blue;">第${chineseNumbers[index]}条　${field?.fieldValue || '<span style="color: red;">_______追加契約内容_______</span>'}</span>`).join('<br />') || '';
    const contractPeriodFormatted = values.contractPeriod ? dayjs(values.contractPeriod).format('YYYY年MM月DD日') : '_______契約期間_______';
    const contractPeriodOneYear = values.contractPeriod ? dayjs(values.contractPeriod).add(1, 'year').format('YYYY年MM月DD日') : '_______契約期間(1年後)_______';
    const contractCreationDate = values.contractCreationDate ? dayjs(values.contractCreationDate).format('YYYY年MM月DD日') : '_______契約日期_______';

    return `
      <h2 style="text-align: center;">契約書</h2>
      <p>${values.contractOrigin ? `<span style="color: blue;">${values.contractOrigin}</span>` : '<span style="color: red;">_______契約元_______</span>'}（以下「甲」という）と、${values.contractDestination ? `<span style="color: blue;">${values.contractDestination}</span>` : '<span style="color: red;">_______契約先_______</span>'}（以下「乙」という）との間で下記の通り取り決めることとする</p>

      <p>第一条　乙は自己の所有する貸切バス<span style="color: blue;">${values.contractUnits || '<span style="color: red;">_______契約台数_______</span>'}</span>台をもって甲の指示に基づき運送業務に従事する。</p>
      <p>第二条　本契約の有効期限は<span style="color: blue;">${contractPeriodFormatted || '<span style="color: red;">_______契約期間_______</span>'}</span>から<span style="color: blue;">${contractPeriodOneYear || '<span style="color: red;">_______契約期間(1年後)_______</span>'}</span>の1年間とする。尚、期間満了の2ヶ月前までに甲乙いずれかからも何らかの意思表示がないときは本契約が同一条件をもって更に<span style="color: blue;">${values.contractRenewal || '<span style="color: red;">_______契約更新_______</span>'}</span>ものとし、以後も同様とする。</p>
      <p>第三条　甲は、乙に対して運賃として、1日<span style="color: blue;">${values.contractPricePerUnit || '<span style="color: red;">_______契約代金単価_______</span>'}</span>円（税込）を<span style="color: blue;">${values.contractUnitsCount || '<span style="color: red;">_______月額分計契約台数_______</span>'}</span>台として一か月￥<span style="color: blue;">${values.contractTotalPrice || '<span style="color: red;">_______月額分計契約代金総価_______</span>'}</span>円（税込）とする。ただし、世界情勢や物価高騰により法定運賃、料金の変動が見込まれる時は甲乙協議の上、契約運賃を見直すこともある。</p>
      <p>第四条　契約運賃は第三条の金額を<span style="color: blue;">${values.contractSettlementDate || '<span style="color: red;">_______契約代金結算日_______</span>'}</span>に入金とする。振込手数料は甲の負担とする。（入金が無い場合、毎月1日からの運行はできないものとする。）</p>
      <p>第五条　契約の稼働日を超えた場合は、1日につき￥<span style="color: blue;">${values.contractPricePerUnit || '<span style="color: red;">_______契約代金単価_______</span>'}</span>円（税込）を別途支払う。</p>
      <p>第六条　稼働日数は、ひと月当たり最低<span style="color: blue;">${values.workingDaysPerMonth || '<span style="color: red;">_______稼働日数/月間_______</span>'}</span>日とする。</p>
      <p>第七条　契約車において、実稼働日数が不足しても稼働日数<span style="color: blue;">${values.workingDaysPerMonth || '<span style="color: red;">_______稼働日数/月間_______</span>'}</span>日分を支払うものとする。</p>
      <p>第八条　車検、法令点検等の日には、前もって連絡し運行できないものとする。</p>
      <p>第九条　配車場所は、<span style="color: blue;">${values.operationSection || '<span style="color: red;">_______運行区間_______</span>'}</span>エリア内発着とする。</p>
      <p>第十条　営業区域外の乗降配車は、運行できないものとする。</p>
      <p>第十一条　運送に関する経費（有料道路代、駐車代、乗務員の宿泊費など）は、甲の負担とする。</p>
      <p>第十二条　<span style="color: blue;">${values.operationBasicCourse || '<span style="color: red;">_______運行基本コース_______</span>'}</span>。</p>
      <p>第十三条　有料道路使用料については、原則乙のETCカードを使用に、乙が立替払いをする。乙は毎月甲に請求する。もしくはツアーによって現地精算とする。</p>
      <p>第十四条　バス及び乗務員の運行時間は、午前8時から午後7時迄の11時間で（回送時間含む）運行を行い、超えた場合は延長時間に応じて料金を協議することとする。上記回送時間とは、車庫から配車場所までの時間も含む運行時間の事で、実車時間ではないものとする。</p>
      <p>第十五条　本契約に定めのない事項が生じた場合はその都度甲乙誠意を持って協議し解決するものとする。</p>

      <p>${additionalFieldsText}</p>
      
      <p>本契約の成立を証するため、本書2通を作成し、甲乙署名（記名）押印の上、各一通を保管する。</p>

      <p style="text-align: right;">以上</p>

      <p><span style="color: blue;">${contractCreationDate}</span></p>

      <p style="text-align: right;">
        甲　東京都中野区本町2-46-1<br />
        <span style="color: blue;">　　${values.contractOrigin || '<span style="color: red;">_______契約元_______</span>'}</span><br />
        　　代表取締役　山添　翔<br /><br />
        乙　<span style="color: blue;">${values.contractCompanyAddress || '<span style="color: red;">_______契約会社住所_______</span>'}</span><br />
        <span style="color: blue;">　　${values.contractCompanyName || '<span style="color: red;">_______契約会社名_______</span>'}</span><br />
        　　代表取締役　<span style="color: blue;">${values.contractCompanyRepresentative || '<span style="color: red;">_______契約会社代表者名_______</span>'}</span>
      </p>
    `;
  };

  useEffect(() => {
    if (visible) {
      const initialValues = form.getFieldsValue();
      setTemplateText(generateTemplateText(initialValues));
    }
  }, [visible, form]);

  const handleValuesChange = (changedValues, allValues) => {
    setTemplateText(generateTemplateText(allValues));
  };

  const handleEditOK = async () => {
    try {
      await form.validateFields();
      let editColumns = form.getFieldsValue();
      editColumns.contractCreationDate = editColumns.contractCreationDate ? dayjs(editColumns.contractCreationDate).format('YYYY-MM-DD') : null;
      editColumns.contractPeriod = editColumns.contractPeriod ? dayjs(editColumns.contractPeriod).format('YYYY-MM-DD') : null;
      delete editColumns.contractDetailId;
      delete editColumns.createAt;
      delete editColumns.hasAdditionalContent;

      let fieldValues = [];
      if (editColumns.additionalFields) {
        fieldValues = editColumns.additionalFields.map((field, index) => ({
          fieldName: `第${numberToChinese(index + 16)}条`, fieldValue: field.fieldValue,
        }));
        const sendAdditionalFields = {userId: editColumns.userId, additionalFields: fieldValues};
        await request.post('/admin/addAdditionalFields', sendAdditionalFields);
      }
      const resEdit = await request.post('/admin/updateContractDetails', editColumns);
      message.success(resEdit.message);
      onCancel();
    } catch (error) {
      console.error('表单校验失败:', error);
    }
  };

  return (<Modal title="編集" width={1000} open={visible} onOk={handleEditOK} onCancel={onCancel} maskClosable={false}
                 closable={true}>
    <Form
      name="user-form"
      form={form}
      onValuesChange={handleValuesChange}
      style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'}}
      layout="vertical"
    >
      <Form.Item label="会社ID" name="userId" style={{width: '30%'}}>
        <Input disabled />
      </Form.Item>

      <Form.Item label="契約元" name="contractOrigin"
                 style={{width: '30%'}}
                 rules={[{required: true, message: '契約元を入力してください'}]}
                 hasFeedback>
        <Input.TextArea rows={1} placeholder={'カモメ・ドリーム'} />
      </Form.Item>

      <Form.Item label="契約先" name="contractDestination"
                 style={{width: '30%'}}
                 rules={[{required: true, message: '契約先を入力してください'}]}
                 hasFeedback>
        <Input.TextArea rows={1} placeholder={'株式会社　○○観光'} />
      </Form.Item>

      <Form.Item label="契約台数" name="contractUnits"
                 style={{width: '30%'}}
                 rules={[{required: true, message: '契約台数を入力してください'}]}
                 hasFeedback>
        <Input.TextArea rows={1} placeholder={'貸切バス○台'} />
      </Form.Item>

      <Form.Item label="契約期間" name="contractPeriod"
                 style={{width: '30%'}}
                 rules={[{required: true, message: '契約期間を入力してください'}]}
                 hasFeedback>
        <DatePicker format="YYYY-MM-DD" style={{width: '100%'}} placeholder={'2024年○○月○○日'} />
      </Form.Item>

      <Form.Item label="契約更新" name="contractRenewal"
                 style={{width: '30%'}}
                 rules={[{required: true, message: '契約更新を入力してください'}]}
                 hasFeedback>
        <Input.TextArea rows={1} placeholder={'1年間自動的に延長される'} />
      </Form.Item>

      <Form.Item label="契約代金単価" name="contractPricePerUnit"
                 style={{width: '30%'}}
                 rules={[{required: true, message: '契約代金単価を入力してください'}]}
                 hasFeedback>
        <Input.TextArea rows={1} placeholder={'1日○○○○円（税込）'} />
      </Form.Item>

      <Form.Item label="月額分計契約台数" name="contractUnitsCount"
                 style={{width: '30%'}}
                 rules={[{required: true, message: '月額分計契約台数を入力してください'}]}
                 hasFeedback>
        <Input.TextArea rows={1} placeholder={'1'} />
      </Form.Item>

      <Form.Item label="月額分計契約代金総価" name="contractTotalPrice"
                 style={{width: '30%'}}
                 rules={[{required: true, message: '月額分計契約代金総価を入力してください'}]}
                 hasFeedback>
        <Input.TextArea rows={1} placeholder={'一か月￥○○○○円（税込）'} />
      </Form.Item>

      <Form.Item label="契約代金結算日" name="contractSettlementDate"
                 style={{width: '30%'}}
                 rules={[{required: true, message: '契約代金結算日を入力してください'}]}
                 hasFeedback>
        <Input.TextArea rows={1} placeholder={'1年間自動的に延長される'} />
      </Form.Item>

      <Form.Item label="稼働日数/月間" name="workingDaysPerMonth"
                 style={{width: '30%'}}
                 rules={[{required: true, message: '稼働日数/月間を入力してください'}]}
                 hasFeedback>
        <Input.TextArea rows={1} placeholder={'毎月末'} />
      </Form.Item>

      <Form.Item label="運行区間" name="operationSection"
                 style={{width: '30%'}}
                 rules={[{required: true, message: '運行区間を入力してください'}]}
                 hasFeedback>
        <Input.TextArea rows={1} placeholder={'都道府県または運輸局名に基づく'} />
      </Form.Item>

      <Form.Item label="運行基本コース" name="operationBasicCourse"
                 style={{width: '30%'}}
                 rules={[{required: true, message: '運行基本コースを入力してください'}]}
                 hasFeedback>
        <Input.TextArea rows={3}
                        placeholder={'運行基本コースは、主に千葉県、成田国際空港、大阪府、関西国際空港、東京都、名古屋市、温泉地区（富士五湖周辺・石和・伊豆・御殿場など）、及び各都市周辺地域で配車する。また、その他のコースが発生した場合には、その都度、甲乙間で打合せの上、調整する。\n'} />
      </Form.Item>

      <Form.Item label="契約日期" name="contractCreationDate"
                 style={{width: '30%'}}
                 rules={[{required: true, message: '契約日期を入力してください'}]}
                 hasFeedback>
        <DatePicker style={{width: '100%'}} placeholder={'2024年○月○日'} />
      </Form.Item>

      <Form.Item label="契約会社住所" name="contractCompanyAddress"
                 style={{width: '30%'}}
                 rules={[{required: true, message: '契約会社住所を入力してください'}]}
                 hasFeedback>
        <Input.TextArea rows={1} placeholder={'東京都新宿区西新宿1-1-1'} />
      </Form.Item>

      <Form.Item label="契約会社名" name="contractCompanyName"
                 style={{width: '30%'}}
                 rules={[{required: true, message: '契約会社名を入力してください'}]}
                 hasFeedback>
        <Input.TextArea rows={1} placeholder={'株式会社　○○観光'} />
      </Form.Item>

      <Form.Item label="契約会社代表者名" name="contractCompanyRepresentative"
                 style={{width: '30%'}}
                 rules={[{required: true, message: '契約会社代表者名を入力してください'}]}
                 hasFeedback>
        <Input.TextArea rows={1} placeholder={'山田　太郎'} />
      </Form.Item>

      <Form.List name="additionalFields" noStyle>
        {(fields, {add, remove}) => (<div style={{width: '100%'}}>
          {fields.map((field, index) => (<Card
            size="small"
            title={`第${chineseNumbers[index]}条`}
            key={field.key}
            extra={<CloseOutlined onClick={() => remove(field.name)} />}
          >
            <Form.Item
              {...field}
              name={[field.name, 'fieldValue']}
              fieldKey={[field.fieldKey, 'fieldValue']}
              rules={[{required: true, message: '入力してください'}]}
            >
              <TextArea rows={3} />
            </Form.Item>
          </Card>))}
          <Button
            type="dashed"
            onClick={() => {
              if (fields.length < 10) {
                add();
              } else {
                message.warning('最大10件の契約を追加できます');
              }
            }}
            block
          >
            追加契約内容
          </Button>
        </div>)}
      </Form.List>
    </Form>

    <ImageWithToggle />
    <div
      style={{
        // whiteSpace: 'pre-wrap',
        marginTop: '20px',
        border: '1px solid #f0f0f0',
        padding: '15px 75px 15px 75px',
        borderRadius: '4px',
        backgroundColor: '#fafafa',
      }}
      dangerouslySetInnerHTML={{__html: templateText}}
    />
  </Modal>);
};

export default EditModal;
