import './index.scss';
import {Card, Form, Input, Button, message} from 'antd';
import React from 'react';
import {useDispatch} from 'react-redux';
import {fetchLogin} from '@/store/modules/user';
import {useNavigate} from 'react-router-dom';
import logo from '@/assets/images/JTMH_logo.png';  // 引入 logo 图片
import CryptoJS from 'crypto-js';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const encryptPassword = (password) => {
    const key = CryptoJS.enc.Utf8.parse('1234567890123456');
    const iv = CryptoJS.lib.WordArray.random(16); // 生成一个随机 IV
    const encrypted = CryptoJS.AES.encrypt(password, key, {
      iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7,
    });
    // 返回 IV 和加密后的数据
    return iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64);
  };

  const onFinish = async (values) => {

    try {
      const encryptedPassword = encryptPassword(values.password);
      const response = await dispatch(fetchLogin({
        username: values.username, password: encryptedPassword,
      }));

      if (response && response.status === '200') {
        navigate('/');
        message.success(response.message);
      } else {
        message.error(response.message);
      }
    } catch (error) {
      message.error('ログインに失敗しました');
    }
  };

  return (<div className="login">
    <a href="https://jtmh.jp/" target="_blank" rel="noopener noreferrer">
      <img className="login-logo" src={logo} alt="Logo" />
    </a>
    <Card className="login-container">
      <div className="login-header">
        <div className="login-title">JTMHバス契約システム</div>
      </div>
      <Form validateTrigger="onBlur" onFinish={onFinish}>
        <Form.Item name="username"
                   rules={[{
                     required: true, message: 'ユーザー名を入力してください',
                   }]}>
          <Input size="large" placeholder="ユーザー名を入力してください" />
        </Form.Item>
        <Form.Item name="password"
                   rules={[{
                     required: true, message: 'パスワードを入力してください',
                   }]}>
          <Input.Password size="large" placeholder="パスワードを入力してください" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" size="large" block>
            ログイン
          </Button>
        </Form.Item>
      </Form>
      <div className="login-footer">Copyright© JTM HOLDINGS CO., LTD. All Rights Reserved.</div>
    </Card>
  </div>);
};

export default Login;