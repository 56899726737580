import axios from 'axios';
import {getToken} from './index';

const request = axios.create({
  baseURL: 'https://bus.kamome-travel.jp/api',
  // baseURL: 'http://192.168.1.65:8081/api',
});

// 添加请求拦截器
request.interceptors.request.use((config) => {
  // 操作这个config注入token
  // 1.获取到token
  // 2.按照后端要求的格式做token拼接
  const token = getToken()
  console.log("token", token)
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
}, (error) => {
  return Promise.reject(error)
})

// 添加响应拦截器
request.interceptors.response.use((response) => {
  // 2xx 范围内的状态码都会触发该函数。
  // 对响应数据做点什么
  return response.data
}, (error) => {
  // 超出 2xx 范围的状态码都会触发该函数。
  // 对响应错误做点什么
  return Promise.reject(error)
})

export {request};
