import React, {useState} from 'react';
import {Button} from 'antd';
import image from '@/assets/images/貸し切りバス契約解説.png';

const ImageWithToggle = () => {
  const [showFull, setShowFull] = useState(false);

  const toggleShow = () => {
    setShowFull(!showFull);
  };

  return (<div style={{
    marginTop: '25px',
    border: '1px solid #f0f0f0',
    overflow: 'hidden',
    height: showFull ? 'auto' : '150px',
    position: 'relative',
  }}>
    <img
      className="image-logo"
      src={image}
      alt="貸し切りバス契約解説"
      onClick={toggleShow}
      style={{
        width: '100%',
        objectFit: 'contain',
        cursor: 'pointer',
        clipPath: showFull ? 'none' : 'inset(0 0 calc(100% - 200px) 0)',
      }}
    />
    {!showFull && (<div
      style={{
        position: 'absolute',
        bottom: '10px',
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        color: 'white',
        padding: '5px 10px',
        borderRadius: '5px',
        cursor: 'pointer',
      }}
      onClick={toggleShow}
    >
      貸し切りバス契約解説クリック見る
    </div>)}
  </div>);
};


export default ImageWithToggle;