import React from 'react';
import {Button} from 'antd';
import {useNavigate} from 'react-router-dom';

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (<div style={{textAlign: 'center', padding: '50px'}}>
      <h1>404 - ページが見つかりません</h1>
      <p>お探しのページは存在しないか、移動された可能性があります。</p>
      <Button type="primary" onClick={() => navigate('/')}>
        ホームに戻る
      </Button>
    </div>);
};

export default NotFoundPage;
