import React, {useState, useRef, useEffect} from 'react';
import {message, Modal, Input, Button, Popconfirm, Drawer} from 'antd';
import SignatureCanvas from 'react-signature-canvas';
import {request} from '@/utils';
import {Worker, Viewer} from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import './index.scss';
import {useNavigate} from 'react-router-dom';
import {CloseOutlined} from '@ant-design/icons';

const {TextArea} = Input;

const UserForm = () => {
  const navigate = useNavigate();
  const [urlToken, setUrlToken] = useState();
  const [userId, setUserId] = useState(0);
  const signBoxRef = useRef(null);

  const [userSignImageBase64, setUserSignImageBase64] = useState(null);
  const [textContent, setTextContent] = useState('');
  const signatureRef = useRef();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [intBoxVisible, setIntBoxVisible] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const hash = window.location.hash;
      const tokenMatch = hash.match(/token=([^&]+)/);
      const token = tokenMatch ? tokenMatch[1] : null;
      setUrlToken(token);
      console.log(token);

      try {
        const response = await request.get('/user/verifyToken?token=' + token);
        const status = response.status;

        if (status === '200') {
          setUserId(response.data.userId);
          const pdfData = response.data.pdfContent;
          const byteCharacters = atob(pdfData);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], {type: 'application/pdf'});
          const url = URL.createObjectURL(blob);
          setPdfUrl(url);
        } else {
          handleStatusError(status);
        }
      } catch (error) {
        console.error('PDFのダウンロードエラー:', error);
        message.error('PDFのダウンロードに失敗しました。');
      }
    };

    fetchData();

    return () => {
      // 清理函数，撤销PDF URL
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl);
      }
    };
  }, []); // 只在第一次加载时执行

  const handleStatusError = (status) => {
    switch (status) {
      case '400':
        message.error('Tokenが見つかりません');
        navigate('/error/token-not-found');
        break;
      case '401':
        message.error('Tokenが使用されている');
        navigate('/error/token-used');
        break;
      case '402':
        message.error('Tokenの期限切れ');
        navigate('/error/token-expired');
        break;
      case '404':
        message.error('契約書が見つかりません');
        navigate('/error/contract-not-found');
        break;
      case '500':
        message.error('契約書の読み取りに失敗しました');
        navigate('/error/contract-read-failed');
        break;
      default:
        message.error('不明なエラー');
        navigate('/error/unknown-error');
        break;
    }
  };

  const handleClearSignature = () => {
    signatureRef.current.clear();
  };

  const intBoxHandleOk = async () => {
    setIntBoxVisible(false);
    const sendDispute = {userId, token: urlToken, disputeContent: textContent};
    const result = await request.post('/user/submitDispute', sendDispute);
    if (result.status === '200') {
      message.success('異議内容送信成功');
      navigate('/dispute-submitted'); // 跳转到新的页面
    } else {
      message.error('異議内容の送信に失敗しました');
    }
  };

  const intBoxHandleCancel = () => {
    setIntBoxVisible(false);
  };

  const handleIntBox = () => {
    setIntBoxVisible(true);
  };

  const handleSaveSignature = async () => {
    const signatureData = signatureRef.current.toDataURL().split(',')[1]; // 将签名保存为DataURL
    setUserSignImageBase64(signatureData);
    console.log(signatureData, '999999999'); // 这里可以将签名数据传送到后端保存或处理
    const sendData = {userId, token: urlToken, base64Image: signatureData};
    const result = await request.post('/user/submitSignature', sendData);
    if (result.status === '200') {
      message.success('送信成功');
      navigate('/signature-completed');
    } else {
      message.error('送信に失敗しました');
    }
  };

  useEffect(() => {
    const resizeCanvas = () => {
      if (signatureRef.current) {
        const canvas = signatureRef.current.getCanvas();
        canvas.width = canvas.offsetWidth;
        canvas.height = canvas.offsetHeight;
      }
    };

    if (drawerVisible) {
      resizeCanvas();
    }

    window.addEventListener('resize', resizeCanvas);
    return () => {
      window.removeEventListener('resize', resizeCanvas);
    };
  }, [drawerVisible]);

  return (<div className="pdf-viewer-container">
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
      <div className="pdf-viewer">
        {pdfUrl ? <Viewer fileUrl={pdfUrl} className="pdf-viewer-son" /> : 'Loading PDF...'}
      </div>
    </Worker>

    <div style={{display: 'flex', justifyContent: 'center', margin: '20px 0'}}>
      <Button type="primary" onClick={() => setDrawerVisible(true)}>
        署名エリアを表示
      </Button>
    </div>
    <Drawer
      title={<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <span>署名エリア</span>
        <Button
          type="text"
          icon={<CloseOutlined />}
          onClick={() => setDrawerVisible(false)}
        />
      </div>}
      placement="right"
      closable={false}
      onClose={() => setDrawerVisible(false)}
      visible={drawerVisible}
      width={600}
    >
      <div className="sign-box" style={{width: '100%', height: '100%'}}>
        <div name="signBoxRef" className="border-example" style={{width: '100%', height: '300px'}}>
          <SignatureCanvas
            ref={signatureRef}
            penColor="black"
            canvasProps={{
              className: 'signature-canvas', style: {width: '100%', height: '100%'},
            }}
          />
        </div>
        <div className="signbox-buttonbox">
          <Button className="sign-button" type="primary" danger ghost onClick={handleClearSignature}>修正</Button>
          <Popconfirm
            title={<div>送信しますか？<br />署名が完了したことを確認してください。</div>}
            onConfirm={handleSaveSignature}
            onCancel={() => message.info('送信がキャンセルされました')}
            okText="送信"
            cancelText="キャンセル"
          >
            <Button className="sign-button" type="primary">
              送信
            </Button>
          </Popconfirm>
          <Button className="sign-button" type="primary" danger onClick={handleIntBox}>異議</Button>
        </div>
      </div>
    </Drawer>
    <Modal
      title="異議内容"
      open={intBoxVisible}
      onOk={intBoxHandleOk}
      onCancel={intBoxHandleCancel}
      okText="発送"
      cancelText="キャンセル"
    >
      <TextArea
        value={textContent}
        onChange={(e) => setTextContent(e.target.value)}
        placeholder="異議の内容をご記入してください"
        autoSize={{
          minRows: 6, maxRows: 9,
        }}
      />
    </Modal>
  </div>);
};

export default UserForm;
