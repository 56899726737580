import React, {useEffect, useState} from 'react';
import {
  EyeTwoTone, DeleteTwoTone, PlusOutlined, SearchOutlined, DeleteOutlined,
} from '@ant-design/icons';
import {
  Table, Popconfirm, Form, Card, Button, Space, Modal, Descriptions, Input, message, Alert, DatePicker,
} from 'antd';
import {request} from '@/utils';
import qs from 'qs';
import PreviewPdfButton from '@/pages/Home/components/PreviewPdfButton';
import FinalPdfButton from '@/pages/Home/components/FinalPdfButton';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import ContractStatusCounts from '@/pages/Home/components/ContractStatusCounts';
import EditModal from '@/pages/Home/components/EditModal';  // 引入图片

const {Search} = Input;

const getRandomUserParams = (params) => ({
  size: params.pagination?.pageSize,
  page: params.pagination?.current - 1,
  contractStatus: params.filters?.contractStatus?.[0],

  // ...params,
});

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Tokyo');

const App = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  // 详细模块可见状态
  const [detailVisible, setDetailVisible] = useState(false);
  const [disputeVisible, setDisputeVisible] = useState(false);
  const [AddVisible, setAddVisible] = useState(false);
  const [dispute, setDispute] = useState([]);
  const [activeSearch, setActiveSearch] = useState({type: null, keyword: ''});  // 新增状态管理搜索类型和关键字
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [tableParams, setTableParams] = useState({

    pagination: {
      current: 1, pageSize: 10, showSizeChanger: true,
    },
  });
  // 保存的当前契约信息
  const [dataRecord, setDataRecord] = useState({});
  const [userEmail, setUserEmail] = useState(''); // 添加 userEmail 状态
  const [hasAdditionalContent, setHasAdditionalContent] = useState(false);
  const [additionalFields, setAdditionalFields] = useState([]);
  // 保存的当前用户信息
  const [firstDataRecord, setFirstDataRecord] = useState({});
  const [form] = Form.useForm();
  const [addForm] = Form.useForm();

  // 列表配置
  const items = [{
    key: '1', label: '会社ID', name: 'userId', children: dataRecord.userId, style: {width: '25%'},
  }, {
    key: '2',
    label: '契約元(カモメ・ドリーム)',
    name: 'contractOrigin',
    children: dataRecord.contractOrigin,
    style: {width: '25%'},
  }, {
    key: '3',
    label: '契約先',
    name: 'contractDestination',
    children: dataRecord.contractDestination,
    style: {width: '25%'},
  }, {
    key: '4', label: '契約台数', name: 'contractUnits', children: dataRecord.contractUnits,
  }, {
    key: '5', label: '契約期間', name: 'contractPeriod', children: dataRecord.contractPeriod,
  }, {
    key: '6', label: '契約更新', name: 'contractRenewal', children: dataRecord.contractRenewal,
  }, {
    key: '7', label: '契約代金単価', name: 'contractPricePerUnit', children: dataRecord.contractPricePerUnit,
  }, {
    key: '8', label: '月額分計契約台数', name: 'contractUnitsCount', children: dataRecord.contractUnitsCount,
  }, {
    key: '9', label: '月額分計契約代金総価', name: 'contractTotalPrice', children: dataRecord.contractTotalPrice,
  }, {
    key: '10', label: '契約代金結算日', name: 'contractSettlementDate', children: dataRecord.contractSettlementDate,
  }, {
    key: '11', label: '稼働日数/月間', name: 'workingDaysPerMonth', children: dataRecord.workingDaysPerMonth,
  }, {
    key: '12',
    label: '運行区間 (都道府県又は運輸局名に基づく)',
    name: 'operationSection',
    children: dataRecord.operationSection,
  }, {
    key: '13',
    label: '運行基本コース',
    name: 'operationBasicCourse',
    children: dataRecord.operationBasicCourse,
    style: {width: '25%'},
  }, {
    key: '14', label: '契約日付', name: 'contractCreationDate', children: dataRecord.contractCreationDate,
  }, {
    key: '15', label: '契約会社住所', name: 'contractCompanyAddress', children: dataRecord.contractCompanyAddress,
  }, {
    key: '16', label: '契約会社名', name: 'contractCompanyName', children: dataRecord.contractCompanyName,
  }, {
    key: '17',
    label: '契約会社代表者名',
    name: 'contractCompanyRepresentative',
    children: dataRecord.contractCompanyRepresentative,
  }, {
    key: '18', label: '契約作成時間', name: 'createAt', children: dataRecord.createAt,
  }];

  const columns = [{
    title: '会社ID', dataIndex: 'userId',
  }, {
    title: '会社名', dataIndex: 'userName',
  }, {
    title: '電話番号', dataIndex: 'userPhone',
  }, {
    title: 'メールアドレス', dataIndex: 'userEmail',
  }, {
    title: '契約状態', dataIndex: 'contractStatus', filterMultiple: false, filters: [{
      text: '新規会社　契約書なし', value: 0,
    }, {
      text: '契約内容が保存された', value: 1,
    }, {
      text: '契約書が送信された', value: 2,
    }, {
      text: '契約書は署名済み', value: 3,
    }, {
      text: '異議あり', value: 4,
    }, {
      text: '署名済みの契約書が送信完了', value: 5,
    }], render: (text) => {
      let message;
      let type;
      let showIcon = true; // 设置是否显示图标
      let alertStyle = {width: '250px'}; // 自定义宽度
      switch (text) {
        case 0:
          message = '新規会社　契約書なし';
          type = 'error';
          break;
        case 1:
          message = '契約内容が保存された';
          type = 'warning';
          break;
        case 2:
          message = '契約書が送信された';
          type = 'info';
          break;
        case 3:
          message = '契約書は署名済み';
          type = 'success';
          break;
        case 4:
          message = '異議あり';
          type = 'error';
          break;
        case 5:
          message = '署名済みの契約書が送信完了';
          type = 'success';
          break;
        default:
          message = '不明';
          type = 'info';
      }
      return <Alert message={message} type={type} showIcon={showIcon} style={alertStyle} />;
    },
  }, {
    title: '作成日付', dataIndex: 'createAt',
  }, {
    title: (<div>
      操作</div>), fixed: 'right', render: (_, record) => {
      return (<Space size="middle">
        <Button icon={<EyeTwoTone />} onClick={() => handleDetailShow(record)} />
        <Popconfirm
          title="削除"
          description="削除しますか？"
          onConfirm={() => deleteItems([record.userId])}
          okText="Yes"
          cancelText="No"
        >
          <Button
            icon={<DeleteTwoTone />}
          />
        </Popconfirm>
      </Space>);
    },
  }];

  // 点击查看按钮
  const handleDetailShow = async (record) => {
    const resDetail = await request.get(`/admin/getContractDetailsByUserId?userId=${record.userId}`);
    // 保存数据给编辑表单
    form.setFieldsValue({
      ...resDetail.data.contractDetails?.[0],
      additionalFields: resDetail.data.additionalFields, // contractCreationDate: record.contractCreationDate ? dayjs(record.contractCreationDate) : null,
      // contractPeriod: record.contractPeriod ? dayjs(record.contractPeriod) : null,
      contractCreationDate: resDetail.data.contractDetails?.[0].contractCreationDate ? dayjs(resDetail.data.contractDetails[0].contractCreationDate) : null,
      contractPeriod: resDetail.data.contractDetails?.[0].contractPeriod ? dayjs(resDetail.data.contractDetails[0].contractPeriod) : null,

    });
    // 保存数据给显示列表
    setFirstDataRecord(record);
    setDataRecord(resDetail.data.contractDetails?.[0]);
    setUserEmail(record.userEmail); // 保存 userEmail 到状态
    setHasAdditionalContent(resDetail.data.contractDetails?.[0]?.hasAdditionalContent || false);
    setAdditionalFields(resDetail.data.additionalFields || []);
    console.log('点击查看按钮', resDetail.data.contractDetails?.[0]);
    setDetailVisible(true);
  };

  // 删除
  const deleteItems = async (data) => {

    let userIds;
    if (data.length > 0) {
      userIds = data.join(',');
      console.log('cunzaizhi');
    } else {
      userIds = selectedRowKeys.join(',');
    }

    const result = await request.post('/admin/deleteUser', data);

    console.log('result', result);

    if (result.status === '200') {
      message.success(result.message);
      setSelectedRowKeys([]);
      fetchData();

    } else {
      message.error(result.message);
    }


  };
  // 通用数据获取函数
  const fetchData = async () => {
    setLoading(true);

    try {
      let res;
      if (activeSearch.keyword) {
        if (activeSearch.type === 'userName') {
          res = await request.get(`/admin/getAllUsers?userName=${activeSearch.keyword}&${qs.stringify(getRandomUserParams(tableParams))}`);
        } else {
          res = await request.get(`/admin/getAllUsers?userPhone=${activeSearch.keyword}&${qs.stringify(getRandomUserParams(tableParams))}`);
        }

        if (res.status === '200') {
          setData(res.data.users);
          setTableParams({
            ...tableParams, pagination: {
              ...tableParams.pagination, total: res.data.totalItems, // 200 is mock data, you should read it from server
              // total: data.totalCount,
            },
          });
        } else {
          setData(null);
        }
      } else {
        res = await request.get(`/admin/getAllUsers?${qs.stringify(getRandomUserParams(tableParams))}`);
        setData(res.data.users);
        setTableParams({
          ...tableParams, pagination: {
            ...tableParams.pagination, total: res.data.totalItems, // 200 is mock data, you should read it from server
            // total: data.totalCount,
          },
        });
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setLoading(false);
  };

  // 当分页、搜索条件改变时重新获取数据
  useEffect(() => {
    fetchData();
  }, [tableParams.pagination?.current, tableParams.pagination?.pageSize, activeSearch, // tableParams?.sortOrder,
    // tableParams?.sortField,
    JSON.stringify(tableParams.filters)]);

  // 记录关键字搜索输入信息
  const handleSearch = (type, value) => {
    setActiveSearch({type, keyword: value});
  };

  const handleDetailClose = () => {
    setDetailVisible(false); // 关闭确认弹窗
  };

  // 获取异议
  const getDispute = async () => {
    const resDispute = await request.get(`/admin/getDisputeContentByUserId?userId=${dataRecord.userId}`);
    console.log('获取异议', resDispute);
    setDispute(resDispute.data);
    setDisputeVisible(true);
  };

  // 页面的打开和关闭
  const disputeHandleOk = () => {
    setDisputeVisible(false);
  };

  const addHandleOk = async () => {
    try {
      const addFormValues = await addForm.validateFields(); // 校验表单字段
      console.log('Received values from form:', addFormValues); // 输出表单数据到控制台
      const resAdd = await request.post('/admin/createUser', addFormValues); // 使用表单数据代替editColumns
      message.success(resAdd.message); // 显示成功信息
      fetchData(); // 调用fetchData函数
      setAddVisible(false); // 关闭Modal
    } catch (errorInfo) {
      console.error('Validation failed:', errorInfo); // 输出校验失败信息
    }
    setAddVisible(false);
  };

  const addHandleCancel = () => {
    setAddVisible(false);
  };

  const handleEditClose = () => {
    setEditVisible(false);
  };

  // 页面参数改变时更新状态
  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      sortOrder: Array.isArray(sorter) ? undefined : sorter.order,
      sortField: Array.isArray(sorter) ? undefined : sorter.field,
    });
    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys, onChange: onSelectChange,
  };

  const validateMessages = {
    required: '${label}を入力してください', types: {
      email: '${label}の形式が正しくありません',
    }, pattern: {
      mismatch: '${label}の形式が正しくありません',
    },
  };

  const onFinish = (values) => {
    console.log('Success:', values);
    addHandleOk();
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const userPhoneRegex = /^[0-9]{10,11}$/;

  return (<>
    <div gutter={16} style={{marginBottom: 16}}>
      <Button
        type="primary"
        onClick={() => {
          setAddVisible(true);
        }}
        style={{
          width: '120px', marginRight: '20px',
        }}
        icon={<PlusOutlined />}
      >
        新規会社
      </Button>

      <Search
        placeholder="会社名で検索"
        onSearch={value => handleSearch('userName', value)}
        enterButton={<span><SearchOutlined /> 検索</span>}
        style={{
          width: '15%', marginRight: '20px',
        }}
      />
      <Search
        placeholder="電話番号で検索"
        onSearch={value => handleSearch('userPhone', value)}
        enterButton={<span><SearchOutlined /> 検索</span>}
        style={{
          width: '15%', marginRight: '20px',
        }}
      />
      <Popconfirm
        title="削除"
        description="削除しますか？"
        onConfirm={() => deleteItems(selectedRowKeys)}
        okText="Yes"
        cancelText="No"
      >
        <Button
          style={{
            marginRight: '20px',
          }}
          type="primary"
          icon={<DeleteOutlined />}
        >
          削除
        </Button>
      </Popconfirm>
      <ContractStatusCounts />
    </div>
    <Table
      rowSelection={rowSelection}
      columns={columns}
      rowKey={record => record.userId}
      dataSource={data}
      pagination={tableParams.pagination}
      loading={loading}
      onChange={handleTableChange}
    />
    <Modal
      title="契約詳細内容"
      open={detailVisible}
      onOk={handleDetailClose}
      onCancel={handleDetailClose}
      footer={null}
      width={1000}
    >
      <Descriptions size="small" layout="vertical" bordered items={items} column={{
        xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xxl: 4,
      }} />

      {dataRecord.hasAdditionalContent ? (<div>
        <p style={{fontWeight: 'bold', fontSize: '16px'}}>追加契約内容:</p>
        {additionalFields.map((field) => (<Card
          key={field.id}
          title={field.fieldName}
          bordered={false}
          style={{marginBottom: '16px', border: '1px solid #f0f0f0', borderRadius: '8px'}}
        >
          {/*<p><strong>契約条目:</strong> {field.fieldName}</p>*/}
          <p> {field.fieldValue}</p>
        </Card>))}
      </div>) : (<p style={{color: 'gray'}}>追加契約内容なし</p>)}

      <div style={{
        marginRight: '20px', marginTop: '25px',
      }}>
        <Button
          type="primary"
          onClick={() => {
            setEditVisible(true);
            form.validateFields();
          }}
          disabled={dataRecord.contractStatus !== 0 && dataRecord.contractStatus !== 1 && dataRecord.contractStatus !== 4}
          style={{
            width: '100px',
            marginRight: '25px',
            backgroundColor: (dataRecord.contractStatus === 0 || dataRecord.contractStatus === 1 || dataRecord.contractStatus === 4) ? 'blue' : 'gray',
            borderColor: (dataRecord.contractStatus === 0 || dataRecord.contractStatus === 1 || dataRecord.contractStatus === 4) ? 'blue' : 'gray',
            color: 'white', // 确保文字颜色在灰色背景上仍然可见
          }}
        >
          編集
        </Button>

        <PreviewPdfButton userId={dataRecord.userId} userEmail={userEmail} dataRecord={dataRecord} />
        <FinalPdfButton userId={dataRecord.userId} userEmail={userEmail} dataRecord={dataRecord} />

        <Button
          type="primary"
          onClick={getDispute}
          disabled={dataRecord.contractStatus !== 4}
          style={{
            width: '100px',
            marginLeft: '350px',
            backgroundColor: dataRecord.contractStatus === 4 ? 'red' : 'gray',
            borderColor: dataRecord.contractStatus === 4 ? 'red' : 'gray',
            color: 'white', // 确保文字颜色在灰色背景上仍然可见
          }}
        >
          異議
        </Button>
      </div>
    </Modal>
    <EditModal
      visible={editVisible}
      onCancel={handleEditClose}
      form={form}
    />
    <Modal title="異議内容" open={disputeVisible} onOk={disputeHandleOk} onCancel={disputeHandleOk}>
      <p>{dispute}</p>
    </Modal>
    <Modal title="新規会社" open={AddVisible} onOk={addForm.submit} onCancel={addHandleCancel}>
      <Form
        form={addForm}
        name="basic"
        layout="vertical" // 使用vertical布局，文字显示在上方
        style={{maxWidth: 600}}
        validateMessages={validateMessages}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="会社名"
          name="userName"
          rules={[{required: true}]}
          hasFeedback>
          <Input style={{width: '100%'}} />
        </Form.Item>

        <Form.Item
          label="会社電話番号"
          name="userPhone"
          rules={[{required: true}, {pattern: userPhoneRegex, message: '電話番号が正しくありません'}]}
          hasFeedback>
          <Input style={{width: '100%'}} />
        </Form.Item>

        <Form.Item
          label="会社メールアドレス"
          name="userEmail"
          rules={[{required: true}, {type: 'email'}]}
          hasFeedback>
          <Input style={{width: '100%'}} />
        </Form.Item>
      </Form>

    </Modal>
  </>);
};

export default App;