import React from 'react';
import {Button} from 'antd';
import {useNavigate} from 'react-router-dom';

const ContractReadFailed = () => {
  const navigate = useNavigate();

  return (<div style={{textAlign: 'center', padding: '50px'}}>
      <h1>契約書の読み取りに失敗しました</h1>
      <p>契約書の読み取り中にエラーが発生しました。</p>
      <Button type="primary" onClick={() => navigate('/')}>
        ホームに戻る
      </Button>
    </div>);
};

export default ContractReadFailed;
