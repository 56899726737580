import Layout from '@/pages/Layout';
import Login from '@/pages/Login';
import UserSign from '@/pages/UserSign';
import Home from '@/pages/Home';
import {createHashRouter} from 'react-router-dom';
import {AuthRoute} from '@/components/AuthRoute';
import TokenNotFound from '@/pages/UserSign/subPage/TokenNotFound';
import TokenUsed from '@/pages/UserSign/subPage/TokenUsed';
import TokenExpired from '@/pages/UserSign/subPage/TokenExpired';
import ContractNotFound from '@/pages/UserSign/subPage/ContractNotFound';
import ContractReadFailed from '@/pages/UserSign/subPage/ContractReadFailed';
import NotFoundPage from '@/pages/NotFoundPage';
import DisputeSubmitted from '@/pages/UserSign/subPage/DisputeSubmitted';
import SignatureCompleted from '@/pages/UserSign/subPage/SignatureCompleted';

const router = createHashRouter([{
  path: '/', element: <AuthRoute><Layout /></AuthRoute>, children: [{
    index: true, element: <Home />,
  }, {
    // path: 'second', element: <Second />,
  }],
}, {
  path: 'login', element: <Login />,
}, {
  path: 'userSign', element: <UserSign />,
}, {
  path: 'error/token-not-found', element: <TokenNotFound />,
}, {
  path: 'error/token-used', element: <TokenUsed />,
}, {
  path: 'error/token-expired', element: <TokenExpired />,
}, {
  path: 'error/contract-not-found', element: <ContractNotFound />,
}, {
  path: 'error/contract-read-failed', element: <ContractReadFailed />,
}, {
  path: 'dispute-submitted', element: <DisputeSubmitted />,
}, {
  path: 'signature-completed', element: <SignatureCompleted />,
}, {
  path: '*', // 通配符路径，匹配所有未定义的路由
  element: <NotFoundPage />,
}]);

export default router;
