import React from 'react';
import {Button} from 'antd';
import {useNavigate} from 'react-router-dom';

const TokenNotFound = () => {
  const navigate = useNavigate();

  return (<div style={{textAlign: 'center', padding: '50px'}}>
      <h1>Tokenが見つかりません</h1>
      <p>指定されたTokenが見つかりませんでした。</p>
      <Button type="primary" onClick={() => navigate('/')}>
        ホームに戻る
      </Button>
    </div>);
};

export default TokenNotFound;
