import React from 'react';
import {Button} from 'antd';
import {useNavigate} from 'react-router-dom';

const TokenUsed = () => {
  const navigate = useNavigate();

  return (<div style={{textAlign: 'center', padding: '50px'}}>
      <h1>Tokenが使用されている</h1>
      <p>指定されたTokenは既に使用されています。</p>
      <Button type="primary" onClick={() => navigate('/')}>
        ホームに戻る
      </Button>
    </div>);
};

export default TokenUsed;
