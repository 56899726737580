import React, {useState} from 'react';
import {Button, message, Modal, Popconfirm} from 'antd';
import {getToken, request} from '@/utils';
import {Worker, Viewer} from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const FinalPdfButton = ({userId, userEmail, dataRecord}) => {

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);

  const finalPdf = async () => {
    try {
      const timestamp = new Date().getTime(); // 获取当前时间戳
      const response = await request.post('/admin/generatePostContractPdf', {userId, timestamp});
      const token = getToken();

      if (response && response.status === '200') {
        const pdfUrl = response.data + '?token=' + token + '&timestamp=' + timestamp;

        setPdfUrl(pdfUrl);
        setIsModalVisible(true);
        message.success('署名ありの契約書が生成されました');
      } else {
        throw new Error('Invalid response');
      }
    } catch (error) {
      console.error('Error fetching PDF:', error);
      message.error('契約書の生成に失敗しました');
    }
  };

  const download = () => {
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.setAttribute('download', '契約書.pdf'); // 指定下载文件的名称
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const send = async () => {
    try {
      console.log(userEmail);
      const sendData = {userId: dataRecord.userId, userEmail};
      const resSend = await request.post('/admin/sendFinalContractEmail', sendData);
      if (resSend && resSend.status === '200') {
        message.success(resSend.message);
      } else {
        throw new Error('Invalid response');
      }
    } catch (error) {
      console.error('Error sending email:', error);
      message.error('メールの送信に失敗しました');
    }
  };

  return (<>
    <Button
      type="primary"
      onClick={finalPdf}
      disabled={dataRecord.contractStatus !== 3 && dataRecord.contractStatus !== 5}
      style={{
        width: '200px',
        marginRight: '20px',
        marginTop: '20px',
        backgroundColor: dataRecord.contractStatus === 3 || dataRecord.contractStatus === 5 ? 'green' : 'gray',
        borderColor: dataRecord.contractStatus === 3 || dataRecord.contractStatus === 5 ? 'green' : 'gray',
        color: 'white', // 确保文字颜色在灰色背景上仍然可见
      }}
    >
      署名完了の契約書読む
    </Button>

    <Modal
      title="署名完了の契約書"
      visible={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
      width="50%"
      footer={[<Button
        key="send"
        type="primary"
        onClick={download}
        style={{
          width: '120px',
        }}
      >
        ダウンロード
      </Button>, <Popconfirm
        title={<div>
          本当にメールを送信しますか？ <br />
          契約会社メールは <strong>{userEmail}</strong>、ご確認お願い致します。
        </div>}
        onConfirm={send}
        okText="送信"
        cancelText="いいえ"
        disabled={dataRecord.contractStatus !== 3 && dataRecord.contractStatus !== 5} // 当条件不满足时禁用Popconfirm
      >
        <Button
          key="send"
          type="primary"
          disabled={dataRecord.contractStatus !== 3 && dataRecord.contractStatus !== 5}
          style={{
            width: '260px',
            backgroundColor: dataRecord.contractStatus === 3 || dataRecord.contractStatus === 5 ? 'deeppink' : 'gray',
            borderColor: dataRecord.contractStatus === 3 || dataRecord.contractStatus === 5 ? 'deeppink' : 'gray',
            color: 'white', // 确保文字颜色在灰色背景上仍然可见
          }}
        >
          契約会社に最終契約書メールを送信
        </Button>
      </Popconfirm>]}
    >
      {pdfUrl && (<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        <div className="pdf-viewer">
          <Viewer fileUrl={pdfUrl} className="pdf-viewer-son" />
        </div>
      </Worker>)}
    </Modal>
  </>);
};

export default FinalPdfButton;