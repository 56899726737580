import React from 'react';
import {Button} from 'antd';
import {useNavigate} from 'react-router-dom';

const SignatureCompleted = () => {
  const navigate = useNavigate();

  return (<div style={{textAlign: 'center', padding: '50px'}}>
    <h1>契約署名が完了しました</h1>
    <p>契約署名が正常に完了しました。ありがとうございます。</p>
    <Button type="primary" onClick={() => navigate('/')}>
      ホームに戻る
    </Button>
  </div>);
};

export default SignatureCompleted;
